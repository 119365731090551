@import "~styles/";

.container {
  background-color: $timeOut;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 24px 16px 16px;
}
.mobileLogo {
  display: flex;
}

.icons {
  display: flex;
  align-items: center;
}

.icons a {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0.2px;
  color: $white;
  margin-right: 32px;
}
.subscribe {
  display: none;
}

.loginIcon {
  margin-right: 32px;

  @include mobileL {
    margin-right: 16px;
  }
}

.profileIcon {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1.6px solid $white;
  color: $white;
  margin-right: 32px;
  cursor: pointer;
  padding-top: 16px;
}

.container svg {
  cursor: pointer;
  margin-top: 2px;
}
.mobileLogo {
  display: flex;
}

@media (min-width: 768px) {
  .container {
    height: 96px;
    padding-left: 95px;
    padding-right: 95px;
  }

  .subscribe {
    display: flex;
  }
}
