$mobileS: 320px;
$mobileM: 375px;
$mobileL: 428px;
$tablet: 768px;
$desktop: 900px;
$desktopM: 1000px;
$desktopL: 1100px;
$desktopXL: 1300px;

$error-color: #d31a22;
$black: #1a1a1a;
$timeOut: #e1192c;
$timeOutDark: #c11929;
$white: #fff;
$grey100: #e8e8e8;
