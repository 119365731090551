@import "~styles/";

.list {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  border-bottom: 0.1px solid $white;
  padding: 0 0 24px;
}

.listItem {
  text-align: left;
  padding: 16px 0;
}

.listItem a {
  color: $white;
  cursor: pointer;
  font-size: 31px;
  font-style: normal;
  font-weight: 850;
  line-height: 37.2px; /* 120% */
  letter-spacing: -0.155px;
  position: relative;
}

.listItem a::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  bottom: 0;
  height: 8px;
  margin: -5px 0;
  left: 0;
  background-color: $white;
  transition: all 0.5s ease-out 0s;
}
.listItem a:hover::after {
  width: 100%;
  animation: bounce-in-left 0.5s ease-out 1;
}
