@import './mixins.scss';
@import './variables.scss';

@keyframes bounce-in-left {
    0% {
        width: 0;
    }
    70% {
        width: 102%;
    }
    100% {
        width: 100%;
    }
}
