.container {
  height: 2px;
  width: 260px;
  background-color: #e8e8e8;
}

.progressBar {
  height: 100%;
  background-color: #e1192c;
}
