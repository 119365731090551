@import "~styles";

.resultsContainer {
  margin: 0 auto;
  max-width: 1252px;

  .totalCount {
    color: $black;
    font-weight: 800;
  }

  .productList {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 0 auto;

    @include tablet {
      grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
    }

    @include mobileL {
      display: block;
      > a {
        margin: 0 auto 20px;
        max-width: 380px;
      }
    }
  }
}

.progressCount {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  align-items: center;
}

.progressCount > span {
  font-family: "Franklin Gothic", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #757575;
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  margin: 24px 0 0;
}

.loadMoreButton button {
  width: 100%;
  max-width: 348px;
  height: 48px;
  padding: 15px 30px 13px;
  background-color: #e1192c;
  border-radius: 38px;
  font-family: "Franklin Gothic", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  cursor: pointer;
  letter-spacing: 0.2px;
}

.loadMoreButton button:hover {
  background-color: #c00f22;
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.12),
      hsla(0, 0%, 100%, 0.12)
    ),
    #e1192c;
}
