@import "~styles/";

.container {
  height: auto;
  width: 100%;
  background: $white;
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 80px;
  left: 0;
  transition: opacity 0.3s ease-in-out 0s, height 0.3s ease-in-out,
    visibility 2s ease-in-out;
}

.open {
  opacity: 1;
  z-index: 2;
}
.profileList {
  margin: 24px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.profileListItem {
  display: flex;
  align-items: end;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.2px;
}

.profileListItem a,
.profileListItem button {
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.2px;
  font-family: unset;
}
.profileListItem:first-child {
  margin-bottom: 12px;
}

.profileListItem:last-child {
  border-top: 1px solid $grey100;
  margin-top: 24px;
  padding-top: 24px;
  padding-left: 2px;
}

.profileListItem svg {
  margin-right: 12px;
}

.profileListItem:last-child button {
  margin-bottom: -2px;
}

.profileList button {
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

@media (min-width: 768px) {
  .container {
    top: 145px;
  }

  .profileList {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .profileListItem:first-child {
    margin: 0;
  }

  .profileListItem:last-child {
    border: none;
    margin: 0;
    padding: 0;
  }

  .profileListItem::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    bottom: -12px;
    height: 4px;
    margin: -5px 0;
    left: 0;
    background-color: $timeOut;
    transition: all 0.5s ease-out 0s;
  }
  .profileListItem:hover::after {
    width: 100%;
    animation: bounce-in-left 0.5s ease-out 1;
  }
}
