.wrapper {
  display: block;
  margin-top: 30px;

  a {
    margin-right: 30px;
  }

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.icons {
  display: flex;
  align-items: center;
}
