@import "../../../../../../../styles";

.categoryHeader {
  text-align: center;
  max-width: 940px;
  margin: 0 auto;
  padding: 26px 32px;

  .searchField {
    margin: 0 auto;
    width: 633px;
    max-width: 100%;
    margin-top: 32px;
  }

  h1 {
    font-weight: 800;
    font-size: 48px;
    margin: 18px 0;

    span {
      color: $timeOut;
      text-transform: capitalize;
    }

    @include tablet {
      margin-bottom: 12px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    line-height: 28px;
  }

  @include tablet {
    text-align: left;
    padding: 6px 26px;
  }
}
