@import "~styles";

.categoryWidget {
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  overflow: hidden;

  @include mobileL {
    min-height: auto;
  }

  .productImage {
    overflow: hidden;
    position: relative;
    aspect-ratio: 1 / 1;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto;
      max-width: none;
      transition: all 0.3s ease-in-out;
    }

    img:hover {
      transform: translateX(-50%) scale(1.06);
      background: red;
    }
  }

  .productBody {
    flex: 1;
    padding: 0 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      font-weight: 500;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .productInfo {
      flex: 1;
    }

    .productTags {
      padding-bottom: 32px;

      > span {
        border-radius: 38px;
        border: 1.6px solid #e8e8e8;
        color: #757575;
        display: inline-block;
        font-size: 16px;
        height: 42px;
        line-height: 28px;
        max-width: 100%;
        overflow: hidden;
        padding: 8px 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .productPrice {
      .discounted {
        color: #fff;
        background-color: $timeOut;
        float: left;
        margin-right: 20px;
      }

      .price {
        font-size: 25px;
        line-height: 36px;

        span {
          font-size: 0.6em;
          font-weight: 400;
          padding-right: 9px;
          top: -2px;
          position: relative;
        }
      }
    }
  }
}
