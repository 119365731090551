.legalText {
  p {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: normal;
    color: #aaaaaa;

    @media (min-width: 768px) {
      width: 515px;
    }
  }
}
