@import "~styles/";

.container {
  height: 100vh;
  width: 100%;
  background: $timeOutDark;
  opacity: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out 0s, height 0.3s ease-in-out,
    visibility 2s ease-in-out;
  overflow-y: hidden;
}

.open {
  opacity: 1;
  z-index: 3;
}

.dropdownHeader {
  background-color: $timeOut;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 24px 16px 16px;
}

.mobileLogo {
  display: flex;
}

.desktopLogo {
  display: none;
}

.menuBody {
  padding: 32px 24px 24px;
}
.additionalLinks {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-bottom: 24px;
  row-gap: 16px;
}

.additionalLinks a {
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 850;
  line-height: 26px;
  letter-spacing: -0.1px;
  color: $white;
  padding: 8px 0;
  position: relative;
  width: fit-content;
}

.additionalLinks a::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  bottom: 0;
  height: 8px;
  margin: -5px 0;
  left: 0;
  background-color: $white;
  transition: all 0.5s ease-out 0s;
}

.additionalLinks a:hover::after {
  width: 100%;
  animation: bounce-in-left 0.5s ease-out 1;
}

.mobileLogo {
  display: flex;
}

.closeButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .dropdownHeader {
    height: 96px;
  }

  .dropdownHeader,
  .menuBody,
  .additionalLinks {
    padding-left: 95px;
    padding-right: 95px;
  }
}
