@import "~styles/";

.container {
  background-color: $timeOut;
  height: 59px;
  scrollbar-width: none;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.container::before {
  content: "";
  position: absolute;
  background: $white;
  height: 1px;
  opacity: 0.4;
  width: 100%;
  z-index: 1;
  top: 0;
}

.navLinks {
  display: flex;
  padding-left: 16px;
  padding-top: 7px;
}

.navLinks a {
  font-size: 16px;
  font-style: normal;
  line-height: 19.2px;
  color: $white;
  margin-right: 24px;
  font-weight: 500;
}

.navButtonWrap {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  opacity: 0;
  position: absolute;
  top: 1px;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.navButtonWrap.active {
  visibility: visible;
  opacity: 1;
}

.navButtonWrapLeft {
  left: 0;
  background: linear-gradient(90deg, $timeOut 50%, rgba(225, 25, 44, 0));
}

.navButtonWrapRight {
  right: 0;
  background: linear-gradient(90deg, rgba(225, 25, 44, 0), $timeOut 50%);
}

.navButtonWrapLeft::after,
.navButtonWrapRight::before {
  content: "";
  display: block;
  height: 100%;
  width: 24px;
}

@media (min-width: 768px) {
  .container {
    margin: 0 95px;
  }

  .container::before {
    width: calc(100% - 190px);
  }

  .navLinks {
    padding-left: 0;
  }

  .navButtonWrapLeft {
    left: 95px;
  }

  .navButtonWrapRight {
    right: 95px;
  }
}

@media (min-width: 1000px) {
  .container {
    display: flex;
  }

  .navButtonWrap {
    display: none;
  }
}
