@import './variables';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Franklin Gothic', sans-serif;
    position: relative;
    color: $black;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#__next {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@font-face {
    font-family: 'FranklinGothicLTPro-DmCm';
    src: url('/fonts/FranklinGothicLTPro/FranklinGothicLTPro-DmCm.woff2');
}

@font-face {
    font-family: 'FranklinGothicLTPro-DmCm';
    src: url('/fonts/FranklinGothicLTPro/FranklinGothicLTPro-DmCm.woff2');
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Regular.woff2');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Medium.woff2');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto/Roboto-Bold.woff2');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

/** Critical: Main **/
@font-face {
    font-family: 'Franklin Gothic';
    src: url('/fonts/franklingothicurw/franklin-gothic-urw-book.woff2') format('woff2');
    font-weight: 300 400;
    font-display: optional;
}

/** medium **/
@font-face {
    font-family: 'Franklin Gothic';
    src: url('/fonts/franklingothicurw/franklin-gothic-urw-medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}

/** demi bold **/
@font-face {
    font-family: 'Franklin Gothic';
    src: url('/fonts/franklingothicurw/franklin-gothic-urw-demi.woff2') format('woff2');
    font-weight: 600 700;
    font-display: optional;
}

/** Critical: Heavy **/
@font-face {
    font-family: 'Franklin Gothic';
    src: url('/fonts/franklingothicurw/franklin-gothic-urw-heavy.woff2') format('woff2');
    font-weight: 800 900;
    font-display: swap;
}
