.searchBar {
  form {
    align-items: center;
    background: white;
    border-radius: 38px;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-wrap: nowrap;
    height: 56px;
    overflow: hidden;
    width: 100%;
  }

  input {
    border: none;
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    padding: 10px 5px 10px 30px;

    &::placeholder {
      color: #aaa;
    }
  }

  button {
    background: white;
    cursor: pointer;
    width: 100px;
    padding: 16px 12px 12px;
  }
}
