@import "~styles";

.tag {
  background-color: $timeOut;
  color: #fff;
  display: inline-block;
  max-width: max-content;
  padding: 6px 12px 0;
  text-transform: uppercase;
}
